* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
}

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
  border: medium none;
  margin: 0;
  padding: 0;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

:focus {
  outline: none !important;
}

img,
fieldset {
  border: 0 none;
}

a {
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: none;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

a:hover {
  text-decoration: none;
  color: red;
  font-family: "Poppins", sans-serif;
}

.clear {
  clear: both;
}

ul::after {
  clear: both;
  content: "";
  display: block;
  font-family: "Poppins", sans-serif;
}

ul {
  list-style: none;
}

i.fa.fa-users {
  margin-right: 9px;
  color: #FCE025;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 27px;
  color: #474646;
  -webkit-font-smoothing: antialiased;
  font-family: "Poppins", sans-serif;
  background: #121518;
  height: 100vh;
}

.logo-top h1,
.logo h1 {
  color: #ffffff;
  font-size: 57px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.logo-top h1,
.logo h1 {
  font-size: 35px;
  font-family: "Poppins", sans-serif;
}

.login-area {
  background: #121518;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.chamge-text {
  font-size: 24px !important;
  font-weight: 300 !important;
}

/* .change-section .login-content-area {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 38%;
} */
.swal-icon--success:after,
.swal-icon--success:before {
  background: transparent !important;
}

.dashboard-main-area.min-height {
  background: transparent;
  padding: 112px 0;
}

.swal-icon--success__hide-corners {
  background-color: transparent !important;
}

.dashboard-area {
  width: 100%;
  margin: 19px 0 0 0;
}

tldx-lmi-shadow-root {
  display: none !important;
}

.admin-header {
  min-width: 87%;
  /* margin-left: 244px; */
}

.login-content-area {
  border-radius: 5px;
  padding: 50px;
  color: #fff;
  background: #0f1113;
}

.logo-top {
  text-align: center;
  margin-bottom: 50px;
}

.login-content-area h2 {
  color: #fff;
  font-size: 33px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
}

.login-content-area form {
  margin: 22px 0 0 0;
}

.min-height .fa-solid {
  position: absolute;
  top: 64px;
  right: 20px;
  font-size: 12px;
  opacity: 0.8;
  cursor: pointer;
}

.login-content-area label.form-label {
  font-weight: 200;
  font-size: 14px;
  margin-right: 12px;
  color: #fff !important;
}

.login-content-area p {
  font-size: 15px;
  text-align: center;
  margin-bottom: 31px;
  font-weight: 200;
}

.login-content-area .form-control {
  border: 1px solid #ffffff45 !important;
  border-radius: 5px;
  padding: 24px;
  color: #fff !important;
  background: transparent;
  font-weight: 400;
  opacity: 1;
  font-size: 14px;
  height: 52px;
  -webkit-text-fill-color: #989494 !important;
}

.table-responsive {
  min-height: 550px;
}

.login-content-area .form-control:focus {
  color: #fff;
  background-color: unset !important;
  outline: unset !important;
  box-shadow: unset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}


.login-content-area button,
.login-content-area button:hover {
  color: #000;
  padding: 11px 45px;
  border-radius: 5;
  font-weight: 600;
  font-size: 17px;
  border: #FCE025;
  background: #FCE025;
  box-shadow: -14px 5px 20px 0px rgb(255 255 255 / 0%);
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-top: 42px;
}

.btn-primary:hover {
  color: #000;
  background-color: #FCE025 !important;
  border-color: #FCE025 !important;
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  color: #000;
  background-color: #FCE025 !important;
  border-color: #FCE025 !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #000;
  background-color: #FCE025;
  border-color: #FCE025;
  box-shadow: unset !important;
}

.btn:hover {
  color: #000 !important;
}

.pagination li {
  padding: 14px;
  color: #fff;
}

.swal-modal {
  border-radius: 5px;
  min-width: 415px;
  width: 415px;
  background: #000000;
  padding: 21px;
  border: none;
}

button.user-back-btn a i {
  margin-right: 7px;
  font-size: 23px;
}

button.user-back-btn a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

button.user-back-btn {
  margin-left: 70px;
  margin-bottom: 30px;
}

.swal-title {
  color: #fff;
  font-size: 21px !important;
}

.pagination {
  margin-top: 14px;
}

.swal-text {
  color: rgb(255 255 255);
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 200;
}

.swal-footer {
  text-align: center;
}

.pagination .disabled {
  opacity: 0.5;
}

.pagination .active a {
  color: #FCE025 !important;
  border: 1px solid;
  padding: 5px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
}

.swal-button {
  background-color: #FCE025 !important;
  border-radius: 5px;
  padding: 8px 19px;
  font-size: 14px;
  color: #000;
}

.swal-icon--warning__body,
.swal-icon--warning__dot {
  background-color: #fce025 !important;
}

.swal-icon {
  border-color: #fce025 !important;
}

.swal-button:not([disabled]):hover {
  background-color: #FCE025;
}

.swal-button:focus {
  box-shadow: unset;
}

.swal-button--cancel {
  background: #000 !important;
  color: #fff;
  border: 1px solid;
}

.pagination li a {
  color: #fff;
  text-decoration: none;
}

.content {
  padding-left: 270px;
  padding-right: 0;
}

.profile-image img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.mian_content {
  background: #fff;
}

.sidebar {
  width: 259px;
  padding: 0;
  position: fixed;
  height: 100%;
  top: 0;
  color: #fff;
  border: 0;
  border-radius: 0;
  background: #090a0b;
  border-left: 2px solid transparent;
}

.logo img {
  filter: brightness(0) invert(1);
  width: 49%;
}

.from-to-filter.form-control {
  width: 100%;
}

.no-dat-found-box {
  height: 68vh;
}

.footer-content {
  padding: 12px;
  position: relative;
  text-align: center;
  width: 80%;
  margin-left: 268px !important;
}

.sidebar_option {
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffffffb5;
  text-decoration: none;
  font-weight: 200;
  padding: 10px 12px;
  margin-bottom: 8px;
  border-radius: 2px;
}

.sidebar_option img {
  margin-right: 9px;
  width: 19px;
  filter: grayscale(100%);
}

a.sidebar_option.active img {
  filter: unset;

}

.user-info-area-section {
  width: 77%;
}

/* .sidebar_option img {
  filter: brightness(0);
} */
.logo {
  text-align: left;
  padding: 22px 10px;
}

.sidebar_option:hover {
  color: #FCE025;
}

.barbtn img {
  filter: brightness(0) invert(1);
  width: 32px;
  margin-left: 12px;
}

.sidebar_option:hover img {
  filter: none;
}

.dashboard-top-filter-area {
  justify-content: flex-end;
  align-items: end;
}

.content.content-home {
  padding-left: 0 !important;
}

.barbtn {
  background: none;
  padding-left: 15px;
  transition: 1s all;
  position: relative;
  top: 21px;
  left: 13%;
  z-index: 999;
}

.mian_content .content {
  padding-left: 0;
  transition: 1s all;
}

.mian_content.active .content {
  padding-left: 240px;
  transition: 1s all;
}

.mian_content.active .barbtn {
  padding-left: 255px;
  transition: 1s all;
}

.footer-content p {
  color: #fff !important;
  text-align: center;
  font-weight: 200;
  font-size: 14px;
}

.custom_height {
  min-height: 773px;
}

.heading-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.add-subscription-btn {
  background: #edd07d;
  border: none;
  color: #000;
}

.userlist-table table {
  background: white;
  border-radius: 15px;
  overflow: hidden;
}

.userlist-table table th {
  padding: 12px 25px !important;
  max-width: 300px;
}

.userlist-table table td {
  padding: 10px 25px;
  max-width: 400px;
}

.user-image img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  min-width: 60px;
  object-fit: cover;
}

.active-status {
  color: #ffc41b;
}

.status-box {
  display: flex;
  align-items: center;
}

.status-box i {
  background: #edd07d;
  padding: 4px;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.mian_content .sidebar {
  left: -270px;
  transition: 1s all;
}

.mian_content.active .sidebar {
  left: 0;
  transition: 1s all;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: 11px;
}
.admin-header {
  padding: 3px;
  padding-right: 0;
  color: #fff;
}

.position-reltive.under-search-header {
  position: relative;
  padding-left: 21px;
}

.position-reltive.under-search-header i {
  position: absolute;
  left: 30px;
  top: 18px;
}

.mian_content.active .admin-header {
  margin-left: 240px;
  transition: 1s all;
}

.logout-btn button {
  background: #090a0b;
  border: none !important;
  border-radius: 5px;
  padding: 3px 6px;
}

.btn-success:hover,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
  background-color: #fce025 !important;
}

.logout-btn button img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.logout-btn .dropdown-menu.show {
  background-color: rgb(28 28 29);
  padding: 14px 22px;
  border-radius: 5px;
}

.logout-btn .dropdown-item {
  color: #fff;
  font-weight: 500;
  padding: 8px 0 !important;
  display: block;
  font-size: 14px;
}

.fa.fa-angle-down {
  position: absolute;
  top: 12px;
  right: 21px;
  pointer-events: none;
  font-size: 14px;
}

.logout-btn .dropdown-item i {
  margin-right: 12px;
}

.under-search-header input {
  background: transparent;
  border: none;
  padding: 13px 42px;
  color: #ffffff9c;
  font-size: 14px;
}

.logout-btn s.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: transparent;
}

.dashboad-content {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 5px;
  color: #000;
  box-shadow: 0 8px 25px 0 rgb(0 0 0 / 10%) !important;
}

.dashboard-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-left h3 {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 20px;
}

.dashboard-right img {
  width: 60px;
  height: 60px;
}

.dashboad-table table tr {
  border-bottom: 1px solid #ffffff08;
}

.dashboad-table {
  margin-top: 30px;
}

.dashboad-table table thead {
  border-bottom: 1px solid #ffffff08;
  color: #000;
}

.dashboad-table table th,
td {
  padding: 3px 7px !important;
  word-break: break-all;
  font-size: 13px;
  color: #fff;
  font-weight: normal;
  vertical-align: -webkit-baseline-middle;
}

.icons-actions i {
  color: #fff;
}

.system-log-area-date-picker input:disabled {
  color: #fff !important;
}

.sidebar_option.active {
  color: #FCE025;
  border-left: 1px solid;
}

.user-management {
  margin-top: 0;
}

.search-area input:focus {
  /* color: white; */
  /* outline: unset !important;
  box-shadow: unset !important; */
  /* background-color: black; */
  background-color: transparent;
  box-shadow: none;
  color: white;
}



.user-toggle .switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 23px;
}

.user-toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

th {
  white-space: nowrap;
}

.transaction-dashboard {
  margin-top: 15px;
}

/* Rounded sliders */
.user-toggle .slider.round {
  border-radius: 34px;
}

.user-toggle .slider.round:before {
  border-radius: 50%;
}

.css-166bipr-Input {
  color: #fff !important;
  font-size: 12px;
}

.main-search-area input.form-control.inner-input {
  min-width: 320px;
}

.css-b62m3t-container {
  width: 212px;
}

/* toggle-end */
.dashboard-area-heading h2 {
  color: #FCE025;
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 600;
}
.view-more-area {
  white-space: break-spaces;
}
.dashboard-area-heading.management-heading {
  /* height: 80vh; */
}

.audit-request-box {
  height: 60vh;
}

.audit-request-box .table-responsive-sm {
  /* padding: 2px 21px 21px 21px; */
  border-radius: 5px;
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: #121518;
  margin-top: 6px;
  padding-bottom: 3px;
  overflow-y: auto;
  min-height: 490px;
  height: 100%;
}

.audit-request-box.under-table-main table td {
  white-space: unset;
}

.UserListTable.table tr td span {
  padding: 4px;
}

div#swal2-html-container {
  color: #fff !important;
  font-size: 14px;
}

h2#swal2-title {
  color: #fff !important;
  font-size: 20px;
}
i.fa-solid.fa-chevron-down , .fa-regular.fa-clock{
  position: absolute;
  top: 58px;
  right: 16px;
  font-size: 12px;
  cursor: pointer;
  pointer-events: none;
}
 .fa-regular.fa-clock{
  top: 52px !important;
}
.no-data-area.notification-section-main {
  text-align: center;
  padding: 14px;
}
.no-data-area.notification-section-main h3 {
  font-size: 14px;
  margin-top: 14px;
  font-weight: 400;
}
.swal2-popup.swal2-modal.swal2-icon-warning.swal2-show {
  background: #000;
  border-radius: 22px;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.swal2-confirm.swal2-styled.swal2-default-outline {
  background: #fbdf25 !important;
  color: #000;
  font-size: 13px;
  border-radius: 9px;
}

button.swal2-cancel.swal2-styled.swal2-default-outline {
  background: gray !important;
  border-radius: 10px;
  font-size: 14px;
}

.swal2-icon.swal2-warning.swal2-icon-show {
  margin: 0 auto;
}

.scroll-data-div {
  height: 73vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.audit-request-box.under-without-pagination {
  height: 71vh;
}

.audit-request-box tr {
  vertical-align: middle;
  /* border-bottom: 1px solid #e1e1e133; */
  color: #fff;
}

.audit-request-box thead {
  border-bottom: 1px solid #e1e1e133;
}

.add-btn button i {
  margin-right: 10px;
}

.mian_content.active .footer-content {
  margin-left: 240px;
  transition: 1s all;
  padding: 20px;
  background: #d7e7f9;
}

.header-dropdown-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-btn.view-btn button i {
  margin-left: 10px;
}

.export-btn.export-btn-nft {
  width: auto;
  margin: 0 10px;
}

.user-info p span {
  color: #fff;
  font-size: 18px;
}

.colored-text {
  color: #086ad8;
}

.user-content-area table {
  background: none;
  box-shadow: none;
}

.management-area-top {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px 0;
  /* overflow: auto; */
}

.management-area-top .filter-data .css-b62m3t-container {
  width: 100%;
}

.user-management.filter-data {
  width: 37%;
  padding: 0 5px;
}

.user-searchbar.search-data {
  width: 33%;
}

.export-btn {
  width: 30%;
}

.export-btn button {
  border: 1px solid #fff;
  color: #fff !important;
  padding: 8px;
  font-size: 14px;
  white-space: nowrap;
  width: 100%;
}

.export-btn i {
  background: none !important;
  font-size: 12px !important;
  margin-right: 5px !important;
  padding: 0 !important;
}

.user-profile img {
  width: 90px;
  height: 90px;
  min-width: 90px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #fff;
}

.pagination-user-management {
  padding-bottom: 15px;
}

.user-management-right-area.mt-0 {
  background-color: #dbdbdb;
  border-radius: 5px;
  min-height: 460px;
}

.user-management-right-area table {
  background: none;
  box-shadow: none;
}

.dashboard-area-top {
  font-size: 26px;
  font-weight: 600;
}

.user-searchbar form {
  width: 164px;
  position: relative;
}

.swal2-html-container {
  color: #000 !important;
}

.list-data {
  cursor: pointer;
}

.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000000bf;
  top: 0;
  z-index: 9999;
  left: 0;
}

h2#swal2-title {
  color: #000;
  font-size: 28px;
  font-weight: 500;
}

.swal2-styled.swal2-confirm, .swal2-styled.swal2-confirm:hover, .swal2-styled.swal2-confirm:focus {
  background-color: #FCE025 !important;
  border-radius: 5px;
  padding: 8px 19px;
  font-size: 14px;
  color: #000;
  border: 1px solid;
  box-shadow: unset !important;
}
button.swal2-cancel.swal2-styled {
  background: #000 !important;
  color: #fff;
  border: 1px solid;
  border-radius: 5px;
  padding: 8px 19px;
  font-size: 14px;
}

.form-select:focus {
  border-color: #d1b9ab !important;
  outline: 0;
  box-shadow: none !important;
}

.loader div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginPageLoader div {
  left: 50% !important;
}

.user-searchbar form .form-control {
  background: none;
  border: 1px solid #fff;
  padding-right: 40px;
}

td.action-area i {
  color: #FCE025;
  border: 1px solid #FCE025;
  padding: 6px;
  border-radius: 4px;
  margin: 0 4px;
}

.user-searchbar form .form-control::placeholder {
  color: #ffff;
  font-size: 13px;
}

.user-searchbar form button .btn-check:focus+.btn,
.btn:focus {
  outline: unset !important;
  box-shadow: unset !important;
}

.nft-area-top {
  justify-content: end;
  margin-bottom: 15px;
}

.nft-area-top .export-btn button {
  margin-right: 10px;
}

.nft-pitcures img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 5px;
}

tr {
  vertical-align: middle;
}

.sub-admin-content-area {
  background-color: rgba(42, 32, 45, 0.5411764705882353);
  box-shadow: inset 0 5px 15px hsl(0deg 0% 64% / 12%);
  border-radius: 10px;
  overflow: hidden;
}

.sub-admin-content-area h5 {
  background: #fd7f1117;
  color: #fff;
  font-size: 16px;
  padding: 8px 14px;
  font-weight: 600;
}

.subadmin-search-area {
  padding: 15px 15px;
}

.subadmin-search-area form {
  width: 100% !important;
}

.subadmin-search-area form i {
  right: 0px;
  bottom: 0px;
  padding: 11px !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
}

.create-users-form-area .form-label {
  margin-bottom: 0;
  color: #fff;
  font-size: 15px;
}

.create-users-form-area .form-control {
  background: none;
  border: 1px solid #999;
  color: #fff;
}

.create-users-form-area .form-control::placeholder {
  font-size: 14px;
  color: #fff;
}

.sub-admin-content-area p {
  color: #fff;
  background: #d6cfff14;
  padding: 9px 15px;
  font-size: 15px;
}

.user-setting-area th {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.add-image-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.file-input {
  position: absolute;
  top: 0px;
  z-index: 1;
  font-size: 0;
  height: 38px;
  cursor: pointer;
  opacity: 0;
}

.user-list-admin h5 {
  background: #fd7f1117;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 14px;
}

.otp-area {
  justify-content: center;
  padding: 45px 0;
}

.otp-area input {
  border: 1px solid hsla(0, 0%, 100%, 0.5490196078431373) !important;
  border-radius: 5px;
  padding: 12px;
  color: #fff !important;
  background-color: #1f1422 !important;
  width: 45px !important;
  height: 45px !important;
}

button.verify-otp-btn {
  padding: 8px 24px;
  margin-top: 10px;
}

tr.user-setting-area {
  background: #2f2334;
}

.sub-admin-content-area td {
  color: #fff;
}

.sub-admin-content-area table tr {
  border-bottom: 1px solid #9b9b9b14;
}

.subadmin-area {
  margin-bottom: 30px;
}

.system-log-area-date-picker input {
  margin-right: 10px;
  background-color: rgba(42, 32, 45, 0.5411764705882353);
  box-shadow: inset 0 5px 15px hsl(0deg 0% 64% / 12%);
  border: 1px solid #fff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  width: 168px;
}

.user-update-box {
  display: flex;
  justify-content: space-between;
  background: #fd7f1117;
}

.user-update-box h5 {
  background: none;
}

.system-log-flex {
  justify-content: space-between;
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.details-name h6 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.details-name p {
  font-size: 15px;
  color: #b9b9b9;
}

.details-name {
  margin-top: 10px;
}

.user-profile-detail {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-profile-detail img {
  margin-right: 10px;
}

.detail-area-two {
  display: block;
}

.detail-area-two .user-info {
  display: flex;
  justify-content: space-between;
}

.detail-area-two .user-info p {
  width: 25%;
  font-size: 14px;
  padding: 10px 4px 0 6px;
  text-align: left;
  border-right: 1px solid #484848;
}

.detail-area-two .user-info p:first-child {
  padding-left: 0;
}

.form-check-input[type="checkbox"] {
  cursor: pointer;
}

.detail-area-two .user-info p:last-child {
  border: none;
}

.detail-area-two .user-info p span {
  font-size: 14px;
}

.not-data-found {
  text-align: center;
}

.not-found {
  text-align: center;
  color: #fff;
  font-size: 26px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transaction-area {
  margin-top: 0;
  background-color: rgba(42, 32, 45, 0.5411764705882353);
  box-shadow: inset 0 5px 15px hsl(0deg 0% 64% / 12%);
  border-radius: 10px;
  color: #fff;
  min-height: 610px;
}

.transaction-area table {
  background-color: unset !important;
  box-shadow: none;
  border-radius: 10px;
  color: #fff;
  overflow: hidden;
}

.transaction-area .export-btn button {
  display: block;
  margin-left: auto;
}

.transaction-area .export-btn {
  padding: 15px 15px 0 0;
}

.err-msg {
  color: red;
}

.system-log-area-date-picker label {
  color: #fff;
}

.management-export {
  max-width: 150px;
}

/* width */
::-webkit-scrollbar {
  width: 3px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #61616175;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.user-info p,
.user-info p span {
  word-break: break-all;
}

.audit-buttons button {
  color: #000;
  padding: 10px 25px;
  font-size: 20px;
  border-radius: 5px;
  font-weight: 400;
  margin-top: 15px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #000;
  background: transparent;
  box-shadow: -14px 5px 20px 0px rgb(8 106 216 / 29%);
}

.audit-buttons {
  text-align: right;
}

.description-box {
  white-space: pre-wrap;
  max-width: 400px;
  /* width: 400px; */
}

.dashboard-main-area {
  min-height: calc(100vh - 110px);
  margin-left: 268px !important;
  position: relative;
  padding: 12px 12px 0 12px;
  border-radius: 5px;
  margin: 8px 12px 0 12px;
  background: #090a0b;
}

.no-found {
  width: 580px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card-content {
  margin-left: 22px;
}

.card-img-view img {
  width: 85px;
  height: auto;
}

.upload-area i {
  font-size: 120px;
  color: #d0d0d0;
  justify-content: center;
  display: flex;
}

.upload-area input {
  width: 120px;
  height: 120px;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: -1;
}

.upload-file-modal .modal-footer {
  justify-content: center;
  border-top: none;
}

.upload-file-modal .modal-header {
  border-bottom: none;
}

.upload-file-modal .modal-title {
  color: #000;
  font-weight: 600;
}

.modal-footer button {
  color: #fff;
  padding: 10px 25px;
  font-size: 20px;
  border-radius: 5px;
  font-weight: 400;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #FCE025;
  background: #FCE025;
  /* box-shadow: -14px 5px 20px 0px rgb(8 106 216 / 29%); */
}

.btn-close {
  opacity: 1;
}

.icons-actions .btn {
  background: #0a6bd7;
  padding: 5px 11px;
  font-size: 13px;
  border: 0;
  color: #fff !important;
  margin: 4px 0 0 0;
  width: 70%;
}

.no-dat-found-box img {
  text-align: center;
  display: block;
  margin: 30px auto;
  width: 5%;
  border-radius: 22px;
  opacity: 0.4;
}

.no-dat-found-box h3 {
  font-size: 17px;
  font-weight: 200;
}

.outer-form {
  box-shadow: 0 8px 25px 0 rgb(0 0 0 / 8%) !important;
  padding: 18px 40px;
  border: 1px solid #dfdfdf;
}

.outer-form label {
  font-size: 13px;
  font-weight: 600;
  color: #000;
}

.outer-form input {
  font-family: "Montserrat", sans-serif;
  padding: 8px;
  font-size: 16px;
}

.outer-form h5 {
  font-size: 23px;
  margin: 22px 0;
  color: #000;
  font-weight: 600;
  text-transform: unset;
}

.outer-form h6 {
  font-weight: 600;
  color: #000;
  font-size: 17px;
}

.vulnerability-box .inner-box {
  padding: 22px;
  background: #f2efef;
  margin: 1px 0;
}

.file-upload {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.file-upload input {
  opacity: 0;
  width: 82px;
  height: 82px;
}

.file-upload .upload-img {
  position: absolute;
  top: 0;
  background: #fff;
  padding: 8px;
  border-radius: 10px;
  pointer-events: none;
  cursor: pointer;
  width: 82px;
  height: 82px;
}

.img-view {
  width: 82px;
  height: 82px;
  background: #fff;
  margin: 0 0 13px 12px;
  border-radius: 11px;
  position: relative;
}

.img-view img {
  width: 100%;
  object-fit: cover;
}

.add-more img {
  width: 14px;
  margin: 0 12px 0 0;
}

.vulnerability-boxes {
  background: #f2efeff5;
  padding: 13px 21px;
  border: 1px solid #f2efeff5;
  margin: 12px 0 12px 0;
}

input[type="radio"] {
  appearance: none !important;
  border: 1px solid #d3d3d3 !important;
  width: 25px;
  height: 25px;
  content: none !important;
  outline: none !important;
  margin: 0 9px 0 0;
  border-radius: 2px !important;
}

input[type="radio"]:checked {
  appearance: none;
  outline: none;
  padding: 0;
  content: none;
  border: none;
}

.form-check-input:checked[type="radio"] {
  background-image: none;
  position: relative;
}

input[type="radio"]:checked::before {
  position: absolute;
  color: #fff !important;
  content: "\00A0\2713\00A0" !important;
  font-weight: bolder;
  font-size: 17px;
  top: -2px;
  left: 1px;
}

.form-check-input:focus,
.form-select:focus {
  box-shadow: unset;
}

.dashboard-main-area label {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.dashboard-main-area .form-control {
  font-size: 14px;
  font-weight: 200;
  padding: 7px 41px 7px 16px;
  background: none;
  color: #fff;
  border: 1px solid #ffffff57;
  border-radius: 5px;
}

.dashboard-main-area .form-select {
  font-size: 14px;
  font-weight: 200;
  padding: 7px 32px 7px 16px;
  background: none;
  color: #fff;
  border: 1px solid #ffffff57;
  border-radius: 5px;
}

.filter-area select.form-control {
  width: 120px;
}

.dashboard-main-area select option {
  color: #000;
}

.dashboard-main-area .form-control::placeholder {
  color: #fff !important;
}

.audit-buttons.inner-table button {
  padding: 6px 10px;
  font-size: 13px !important;
  width: unset;
  margin: 4px;
  width: 70%;
}

.audit-buttons.inner-table {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.dashboard-cards {
  padding: 22px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  display: flex;
  height: 100%;
  color: #fff;
  background: #fff;
  border: 1px solid #FCE025;
}

.card-content h3 {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
}

td.action-area button {
  background: #FCE025;
  color: #fff;
  padding: 2px 15px;
  font-size: 14px;
  margin: 0 5px;
}

button.reject-btn.btn.btn-unset {
  background: none;
  border: 1px solid #FCE025;
  color: #FCE025;
  padding: 2px 20px;
}

td.action-area {
  /* max-width: 120px; */
}

.copy-clipboard i {
  color: #FCE025;
}

.form-check-input:checked {
  background-color: #FCE025;
  border-color: #FCE025;
}

.text-success {
  color: #00a226 !important;
}

.text-danger {
  color: #ff7272 !important;
  font-size: 13px;
  margin-top: 11px;
  display: inline-block;
  line-height: 23px;
  font-weight: 400;
}

td.copy-clipboard a {
  color: #fff !important;

}

i.fa.fa-clipboard {
  color: #FCE025;
  margin-left: 9px;
}

.card-content h2 {
  font-size: 52px;
  font-weight: 800;
  color: #FCE025;
  margin-top: 12px;
}

.search-area {
  position: relative;
}

.search-area form {
  width: 100%;
  margin-right: 12px;
}

.search-area .fa {
  position: absolute;
  top: 9px;
  right: 11px;
  color: #ffffff;
  font-size: 17px;
}

.fa-close {
  color: #000;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FCE025;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FCE025;
}

.css-n83rfd-option:hover,
.css-n83rfd-option.active {
  background: #ffffff1a;
}


.main-heading {
  font-size: 32px;
  margin: 31px 0 0 0;
  color: #FCE025;
  font-weight: 700;
}

.shimmer.shimmer-thumbnail.card-shimmer {
  height: 87px !important;
  margin: 6px !important;
}

/* .filter-select {
  margin-top: 36px;
} */

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.custom-shimmer {
  background: linear-gradient(to right, #ddd8d8 8%, #fdfdfd 18%, #dddddd3b 33%);
  background-size: 1000px 100%;
  animation: shimmer 3.2s linear infinite forwards;
}

.hover-style:hover {
  background: #fbdf2538;
  border-radius: 5px;
}

.hover-style {
  padding: 3px 4px;
}

.shimmer-thumbnail {
  width: 100%;
  height: 32px !important;
  margin-bottom: 20px;
  display: inline-block;
  border-radius: 0;
  margin: 0 0 0 12px;
}

.img-view img {
  width: 100%;
  height: 82px;
  object-fit: contain;
  padding: 10px;
}

.img-view .fa-close {
  position: absolute;
  right: -4px;
  top: -4px;
  background: #FCE025;
  padding: 2px;
  border-radius: 22px;
  color: #fff !important;
  font-size: 11px;
  display: flex;
  width: 17px;
  height: 17px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dashboard-cards a {
  text-decoration: none;
}

.contact-search {
  top: 47px !important;
}

.view-btn {
  background: #086ad7;
  padding: 6px 10px;
  border-radius: 5px;
  color: #fff;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

p.view-all-btn {
  text-align: end;
  color: #FCE025;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.note_area {
  height: 182px;
  overflow: auto;
  font-size: 14px !important;
}

.table td a,
.table td svg {
  color: #fff;
  text-decoration: none;
}

.table td a:hover {
  color: #fff;
}

/* .table td a:hover {
  color: #086ad7 !important;
} */

.img-view.top-logo {
  background: #f2f0f0;
  margin: 0;
}

.btn-view a {
  text-decoration: none !important;
  outline: none;
}

.no-found h2 {
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  margin: 12px 0;
  color: #fff;
}

.no-found p {
  color: #fff;
}

.login-content-area .spinner-border {
  color: #fff !important;
}

.user-info-area {
  padding: 12px 21px;
  border-radius: 5px;
  background: white !important;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  margin-left: 55px;
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  border: 1px solid #FCE025;
  border-radius: 20px;
}

.user-info-area-section h6 {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  padding: 13px 0;
}

.user-info-area-section p {
  color: #FCE025ff;
  font-size: 17px;
  font-weight: 300;
  padding: 10px 0;
  font-weight: 400;
}

.wallet-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.back-btn {
  margin-left: 55px;
  margin-top: 3px;
  background-color: #FCE025;
  border-color: #FCE025
}

.arrow-down-area {
  position: relative;
}

.arrow-down-area i {
  position: absolute;
  right: 12px;
  bottom: 12px;
  color: #000;
}

.edit_profile .form-control {
  color: #000 !important;
}

.fa-edit {
  color: #FCE025;
}

.react-select-main .css-13cymwt-control {
  background: transparent !important;
  color: #fff;
  font-size: 14px;
  border: 1px solid #ffffff73;
}

.react-select-main .css-10wo9uf-option {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}

.react-select-main .css-1nmdiq5-menu {
  background-color: hsl(210deg 10% 3.92%);
  border-radius: 5px;
}

.react-select-main .css-1dimb5e-singleValue {
  color: hsl(0deg 0% 100%);
}

.css-t3ipsp-control {
  background: transparent !important;
}

.react-select-main .css-d7l1ni-option {
  background-color: #000000;
  color: #fff;
  font-size: 14px;
}

.react-select-main .css-1n6sfyn-MenuList {
  overflow-x: hidden;
}

.logo-login {
  text-align: center;
  display: block;
  margin: 0 auto;
  width: 8%;
  position: absolute;
  left: 20px;
  top: 20px;
  filter: brightness(0) invert(1);
}

.o-text {
  width: 9%;
}

.search-area .form {
  width: 100%;
  margin-right: 12px;
}

.all-dropdown-list .dropdown-toggle {
  padding: 7px 17px;
  border-radius: 4px;
  background: transparent;
  color: #fff !important;
  border: none;
  border: 1px solid #ffffff5c;
  font-size: 14px;
}

.all-dropdown-list .dropdown-menu {
  padding: 7px;
  border-radius: 5px;
  background: #090a0b;
  border: 1px solid;
}

.all-dropdown-list .dropdown-item {
  color: #ffffff;
}

.under-dropdown .dropdown-toggle {
  padding: 7px 11px;
  border-radius: 4px;
  background: #fce025 !important;
  color: #000 !important;
  border: none;
  font-size: 13px;
  font-weight: 600;
}

.under-dropdown .dropdown-menu {
  padding: 7px;
  border-radius: 5px;
  background: #090a0b;
  border: 1px solid #ffffff29;
}

.under-dropdown .dropdown-menu a {
  color: #fff !important;
  font-size: 14px;
  font-weight: 400;
}

.completed-transation {
  background: green;
  display: inline-block;
  padding: 3px 9px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.completed-transation.failed {
  background: #d03b3b;
  width: fit-content;
}

.completed-transation.pending {
  background: #ba821a;
  width: fit-content;
}

.card-infor-box {
  padding: 17px;
  margin-bottom: 8px;
  border-radius: 5px;
  background-image: linear-gradient(to top, #111111 0%, #25292e 100%);
  color: #fff;
  display: flex;
  align-items: flex-start;
  margin-top: 7px;
  height: 100%;
}

.card-infor-box h5 {
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 9px;
}
.view-btn-main {
  background: #fbdf25;
  padding: 9px;
  border-radius: 4px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000 !important;
  height: 27px;
  margin: 5px 0;
}
.card-infor-box h3 {
  font-size: 23px;
  font-weight: 700;
  color: white;
  white-space: nowrap;
}

.main-icon-1,
.main-icon-1:hover {
  width: 42px;
  height: 42px;
  display: inline-block;
  background: #2590fa1f;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  margin-right: 16px;
  text-decoration: none;
}

.tooltip-inner {
  font-size: 11px;
  padding: 11px;
}

.back-btn-main,
.back-btn-main:hover {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  opacity: 0.7;
  cursor: pointer;
}

.inner-details-heading {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.wallet-address-inneer {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #fff3;
  padding: 4px 20px;
  border-radius: 5px;
  background: #121518;
}

.wallet-info label {
  font-size: 12px;
  color: #fff;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.transaction-swap-details {
  padding: 22px;
  border: 1px solid #ffffff1c;
  border-radius: 5px;
  background: #121518;
}

.wallet-info {
  padding: 15px 0;
  border-bottom: 1px solid #ffffff21;
  position: relative;
}

.toekn-main-paragraph {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
}

.wallet-address-header {
  padding: 0 0 22px 0;
  border-bottom: 1px solid;
}

.token-details {
  display: flex;
  align-items: center;
}

.token-details span {
  display: flex;
  flex-direction: column;
  line-height: 23px;
}

.wallet-info:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #4e4b4b;
  border-radius: 12px;
  bottom: -6px;
}

.side-base {
  margin-left: 172px;
}

.dashboard-main-area select option {
  font-size: 13px;
}

.fixed-width-wise .dropdown-toggle {
  width: 142px;
}

.user-activities-box {
  background: #121518;
  padding: 0 22px 0 22px;
  border-radius: 5px;
  color: #fff;
  /* margin-top: 11px; */
}


.user-activities-main p {
  font-size: 14px;
}

.user-activities-main div {
  font-size: 13px;
  line-height: 23px;
}

.user-activities-main label {
  color: #fff !important;
  font-size: 13px;
  font-weight: 400;
}

.user-activities-box-underder {
  border-left: 1px solid #ffffff54;
  padding-left: 24px !important;
  position: relative;
  padding: 2px 0 24px 0;
}

.user-activities-box-underder::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background: #fff;
  left: -8px;
  top: 7px;
  border-radius: 22px;
}

.width-wise {
  max-width: 182px;
  padding-right: 32px !important;
}

.width-wise-under {
  width: 27%;
  white-space: break-spaces !important;
}

.select-days .form-select {
  background: unset !important;
  width: fit-content;
  margin-left: 11px;
  cursor: pointer !important;

}

.select-days.position-relative i {
  color: #fff;
  position: absolute;
  top: 17px;
  right: 10px;
  font-size: 12px;
  pointer-events: none;
  cursor: pointer !important;
}

.fa-solid.fa-sort {
  font-size: 11px;
  margin-left: 13px;
  cursor: pointer;
}

.UserListTable tbody tr td:first-child,
.UserListTable thead tr th:first-child {
  padding-left: 24px !important;
}

.no-data-found img {
  width: 120px;
  display: flex;
  margin: 0 auto;
  min-width: 120px;
}

.no-data-found {
  text-align: center;
  padding: 52px 0;
  opacity: 0.5;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.no-data-found h3 {
  font-size: 20px;
  margin-top: 21px;
  font-weight: 300;
}

.view-more {
  color: #fce025;
  cursor: pointer;
}

.add-new-device-popup.unde-text .modal-content {
  padding: 22px;
  background: #000;
  color: #fff;
}

.succefull-txt.main-text {
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  text-align: left;
}

.add-new-device-popup.unde-text .modal-header {
  border-bottom: 1px solid #ffffff36;
  padding-top: 0;
}

.close-btn.btn.btn-primary,
.close-btn.btn.btn-primary:hover {
  background: transparent !important;
  color: #fff !important;
  border-color: #fff !important;
  font-size: 13px;
  margin-top: 21px;
  cursor: pointer;
}

.shimmer {
  background-image: linear-gradient(to right, #212223 8%, #292a2bd4 18%, #212223 33%) !important;
  background-size: 1000px 100% !important;
  border-radius: 8px;
  height: 33px !important;
  margin-bottom: -12px !important;
  margin-top: 11px;
  margin-left: -9px !important;
}
.limit-order-section .audit-request-box, .limit-order-section .dashboard-area-heading.management-heading{
  height: unset;
}

.limit-order-section .audit-request-box,
.limit-order-section .dashboard-area-heading.management-heading {
  height: unset;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  /* position: absolute; */
  left: 11px;
  bottom: 0;
}

.dashboard-main-area.min-height.password-outer {
  min-height: calc(100vh - 150px);
}

.pricing-graph {
  background: #1e2124;
  padding: 14px;
  border-radius: 7px;
}

.subheading-text {
  font-size: 16px;
  margin-bottom: 13px;
  color: #fff;
  font-weight: normal;
  padding: 9px 18px;
  display: inline-block;
  background: #fce0251f;
  border-radius: 5px;
}

.display-token {
  display: flex;
  align-items: center;
}

.under-token-img {
  width: 27px;
  margin-right: 5px;
  border-radius: 40px;
}

.custom-enable-disable.form-switch .form-check-input {
  width: 41px;
  height: 21px;
}
.copied-text {
  position: absolute;
  top: 50%;
  left: 43%;
  transform: translate(-50%, -45%);
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("../images/Ellipse\ 1.svg");
}

.fetch-token {
  position: relative;
  padding: 7px 22px;
  border-radius: 6px;
  border: none;
  color: #000;
  cursor: pointer;
  background-color: #fbdf25;
  transition: all 0.2s ease;
  font-size: 15px;
  font-weight: 700;
}

.fetch-token:active {
  transform: scale(0.96);
}

.search-area.text-right {
  text-align: right;
}

.fetch-token:before,
.fetch-token:after {
  position: absolute;
  content: "";
  width: 150%;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  z-index: -1000;
  background-repeat: no-repeat;
}

.fetch-token:hover:before {
  top: -70%;
  background-image: radial-gradient(circle, #fbdf25 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, #fbdf25 20%, transparent 30%),
    radial-gradient(circle, #fbdf25 20%, transparent 20%),
    radial-gradient(circle, #fbdf25 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #fbdf25 15%, transparent 20%),
    radial-gradient(circle, #fbdf25 20%, transparent 20%),
    radial-gradient(circle, #fbdf25 20%, transparent 20%),
    radial-gradient(circle, #fbdf25 20%, transparent 20%),
    radial-gradient(circle, #fbdf25 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
    10% 10%, 18% 18%;
  background-position: 50% 120%;
  animation: greentopBubbles 0.6s ease;
}

@keyframes greentopBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }

  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }

  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.fetch-token:hover::after {
  bottom: -70%;
  background-image: radial-gradient(circle, #fbdf25 20%, transparent 20%),
    radial-gradient(circle, #fbdf25 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #fbdf25 15%, transparent 20%),
    radial-gradient(circle, #fbdf25 20%, transparent 20%),
    radial-gradient(circle, #fbdf25 20%, transparent 20%),
    radial-gradient(circle, #fbdf25 20%, transparent 20%),
    radial-gradient(circle, #fbdf25 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
  background-position: 50% 0%;
  animation: greenbottomBubbles 0.6s ease;
}

@keyframes greenbottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }

  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }

  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.under-date-component label {
  color: #fff;
  font-weight: normal;
  font-size: 10px;
  line-height: normal;
  position: absolute;
  top: -9px;
  left: 10px;
  background: #0f1011;
  padding: 0px 8px;
}

.under-date-component {
  display: flex;
  flex-direction: column;
  position: relative;
}

.under-date-component input {
  border: 1px solid #ffffff6b !important;
  background: transparent;
}

.address-btn {
  display: flex;
  align-items: center;
}

.address-btn a {
  white-space: nowrap;
}

.fa-calendar-days {
  color: #fff;
  position: absolute;
  top: 20px;
  right: 26px;
  font-size: 14px;
  pointer-events: none;
  cursor: pointer !important;
}

.text-right {
  text-align: right;
}
.add-new-device-popup .modal-content {
  background: #000;
  padding: 30px;
  color: #fff;
  border: 1px solid #ffffff2b;
}
.add-new-device-popup .modal-header {
  padding: 0;
  margin-bottom: 12px;
  border: none;
}

.add-new-device-popup .modal-title {
  font-size: 18px;
}

.add-new-device-popup .modal-body {
  padding: 12px 0px;
}

.add-new-device-popup .form-label {
  font-size: 14px;
}

.add-new-device-popup .form-control, .add-new-device-popup .form-control:focus-visible {
  background: #000;
  padding: 8px 15px;
  font: 14px;
  color: #fff !important;
  box-shadow: unset;
  border: 1px solid #ffffff36;
}

.system-log-area-date-picker.d-flex input {
  cursor: pointer;
}

.add-new-device-popup button.btn-close {
  filter: invert(1);
}

.edit-icon-area img,
.text-name {
  width: 100px !important;
  height: 100px !important;
  min-width: 100px !important;
  min-height: 100px !important;
  border-radius: 50% !important;
  border: 1px solid #ffffff40 !important;
}

.edit-icon-area,
.edit-icon-area-outer {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  position: relative;
}

.edit-icon-inner i {
  width: 30px;
  height: 30px;
  background: #FCE025;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.edit-icon-inner input {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 50%;
}

.edit-icon-outer {
  position: absolute;
  right: -1px;
  bottom: 0;
}

.edit-icon-inner {
  position: relative;
}

button.text-dark.edit-close {
  background: #fff !important;
  border: 1px solid #fff !important;
}

.token-management-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.token-management-right .position-relative i {
  position: absolute;
  right: 8px;
  bottom: 11px;
}

.form-control:focus {
  box-shadow: unset !important;
}

.token-management-right .position-relative {
  margin-bottom: 13px;
}

.tranding-view-shimmer iframe {
  min-height: 600px !important;
}

.audit-request-box.toeken-table-box table tr td {
  white-space: unset;
}

.audit-request-box .table-responsive-sm::-webkit-scrollbar {
  height: 6px;
}

.dashboard-area-heading.management-heading.under-dashboard {
  height: unset;
}

.dashboard-area-heading.management-heading.under-dashboard .pricing-graph {
  margin-bottom: 22px;
}

/*------------------------------*/

.user_detail {
  color: #FCE025;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 500;
}

.user_detailField {
  margin-bottom: 2%;
  padding-left: 30px;
  padding-right: 30px;
  color: #000;
  font-weight: 600;
}

.categoryBtn {
  background-color: #FCE025 !important;
  border: 1px solid #FCE025 !important;
  border-radius: 25px !important;
}

.cat_Edit {
  width: 80px;
  padding: 3px !important;
  font-size: 14px !important;
}

.cat_Delete {
  width: 80px;
  font-size: 14px !important;
  padding: 3px !important;
  margin-left: 5px;
  background: red !important;
}

.cursor-pointer {
  cursor: pointer;
}

.fa-solid {
  line-height: 0;
}

.span-sort {
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 6px;
}

.subcategoryBtn {
  background-color: #FCE025 !important;
  border: 1px solid #FCE025 !important;
}

.sub_Edit {
  width: 80px;
  padding: 2px !important;
  border-radius: 30px !important;
  font-size: 14px !important;
}

.sub_Delete {
  width: 80px;
  margin-left: 5px;
  background: red !important;
  padding: 2px !important;
  border-radius: 30px !important;
  font-size: 14px !important;
}

/* .RecentUserList{
  background-color: pink !important;
}
.RecentUserList thead{
  background-color: pink !important;
}
.RecentUserList thead tr{
  background-color: pink !important;
} */
.view-eye {
  color: black !important;
}

.RecentUserList {
  color: #000 !important;
}

.RecentUserList thead tr th {
  background-color: transparent !important;
  color: black !important;
  border-style: none !important;
}

.RecentUserList tbody tr td {
  background-color: transparent !important;
}

.RecentUserList tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.RecentUserList tbody tr td {
  color: #000 !important;
}


/*-----------------*/
.UserListTable {
  color: white !important;
}

.css-qbdosj-Input {
  padding: 0 !important;
}

.UserListTable thead tr th {
  background-color: transparent !important;
  border-style: none !important;
  font-weight: 300;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 8px 7px;
}

.UserListTable tbody tr td {
  background-color: transparent !important;
  vertical-align: middle;
  position: relative;

}

.token-details .error-text {
  left: 53px;
  bottom: -9px;
}

.error-text {
  position: absolute;
  bottom: -7px;
  left: 8px;
}

.UserListTable tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.UserListTable tbody tr td {
  color: #fff !important;
  white-space: nowrap;
}

/*-------------------*/

.categoryTable {
  color: #000 !important;
}

.categoryTable thead tr th {
  background-color: transparent !important;
  color: #000 !important;
  border-style: none !important
}

.categoryTable tbody tr td {
  background-color: transparent !important;
}

.categoryTable tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.categoryTable tbody tr td {
  color: #000 !important;
}


/*----------------------*/

.subcategoryTable {
  color: white !important;
}

.subcategoryTable thead tr th {
  background-color: transparent !important;
  color: #000 !important;
  border-style: none !important
}

.subcategoryTable tbody tr td {
  background-color: transparent !important;
}

.subcategoryTable tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.subcategoryTable tbody tr td {
  color: #000 !important;
}

/*-------------------------*/
.productListTable {
  color: #000 !important;
}

.productListTable thead tr th {
  background-color: transparent !important;
  color: #000 !important;
  border-style: none !important
}

img.rounded-circle {
  min-width: 35px;
  min-height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #FCE025;
  height: 35px;
  margin-right: 10px;
}

.user-detail-profile img {
  min-width: 120px;
  min-height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #FCE025;
  height: 120px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
}

.change-section {
  min-height: 780px;
}

.productListTable tbody tr td {
  background-color: transparent !important;
}

.productListTable tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.productListTable tbody tr td {
  color: #000 !important;
}



/*---------------product detail-----------------*/

.product-detail {
  border: 1px solid white;
  border-radius: 5px;
}

.product-image-left img {
  width: 100%;
  height: 500px;
  min-height: 500px;
  object-fit: cover;
  border-radius: 26px;
  border: 1px solid #23232330;
}

.product-detail-right h6 {
  font-size: 20px;
  text-transform: uppercase;
  color: #000 !important;
  font-weight: 700;
  margin-bottom: 20px;
}

.product-detail-right h3 {
  color: #000 !important;
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 20px;
}

.product-detail-right h4 span {
  font-size: 15px;
}

.product-detail-right h4 {
  color: #FCE025;
  font-weight: 800;
  margin-bottom: 20px;
}

.product-detail-right p span {
  font-weight: 700;
  color: #FCE025;
  margin-right: 40px;
}

.product-detail-right p {
  font-size: 18px;
  color: #000 !important;
  margin-bottom: 20px;
}

/* 
.product-detail-right {
  padding: 30px 0 0;
} */



.product-availability-order p span {
  color: #FCE025 !important;
  font-weight: 700 !important;
  margin-right: 0 !important;
}

.product-availability-order p {
  font-size: 18px;
  color: #000 !important;
  margin-right: 30px;
  margin-bottom: 0 !important;
}

.product-availability-order {
  display: flex;
  margin-bottom: 20px;
}


.product-multiple-images {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 48px;
}


.product-images-area img {
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  object-fit: cover;
  border-radius: 5px;
}

.product-images-area {
  width: 120px;
  height: 120px;
  background: #80808061;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 12px;
  position: relative;
}

.product-images img {
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 26px;
}

.product-images {
  margin-right: 10px;
  margin-top: 20px;
}

.product-images img {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  object-fit: cover;
  border: 1px solid #23232330;
}

.product-detail.row {
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: #fff;
  border: 1px solid #FCE025;
  border-radius: 20px;
  padding: 25px;
}

.active-user-dropdown {
  height: 40px;
  width: 100px;
  background: 'red';
  border-radius: 10
}

.under-tabs {
  margin-top: 40px;
}

.loader-search {
  width: 18px;
  height: 18px;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  top: 9px;
  right: 22px;
  color: #fce025;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* .search-area input:focus{
  background-color:transparent;
} */

.margin50.row {
  margin-bottom: 18px;
}

.css-1cf01cf input,
.css-1cfo1cf {
  color: #fff !important;
}

.css-t3ipsp-control:focus,
.css-b62m3t-container:focus-visible,
.css-13cymwt-control:focus-visible {
  border: 1px solid #fff !important;
  box-shadow: unset !important;
}

.margin50.under-boes-list {
  display: flex;
}

.width-20 {
  width: 19.5%;
  margin-right: 13px;
  margin-bottom: 23px;
}

.rounded-circle.logo-url {
  display: block;
}

.transactions-management-section .audit-request-box {
  height: unset;
}

.transactions-management-section .dashboard-area-heading.management-heading {
  height: unset;
}

/* .transactions-management-section .audit-request-box {
  height: 92%;
} */
.transactions-management-section .pagination {
  position: unset;
}

.pricing-graph .css-ngwy7v-MuiResponsiveChart-container {
  padding: 25px;
}
.notification-top p {
  background: #E57070;
  width: 20px;
  min-width: 20px;
}
.notification-top img {
  width: 40px;
  min-width: 40px;
  cursor: pointer;
}
.notification-top {
  position: relative;
}
.notification-top p {
  background: #E57070;
  width: 20px;
  min-width: 20px;
  position: absolute;
  top: 3px;
  right: -9px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 20px;
  min-height: 20px;
  font-size: 10px;
}
.notification-menu {
  position: absolute;
  z-index: 1;
  width: 520px;
  right: -2px;
  top: 50px;
  background: var(--Dark-bg, linear-gradient(95deg, #1B1D1F 0%, #151618 100%));
  border: 1px solid #ffffff1c;
  border-radius: 28px;
  padding: 15px;
}
.notification-menu ul li {
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  background: #fce0251c;
  padding: 14px;
  border-radius: 16px;
  margin: 0 9px 12px 0;
}
.notification-menu ul {
  max-height: 270px;
  overflow: auto;
  margin-bottom: 10px;
}
.title-text {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 8px !important;
}
.notification-menu ul li .notification-item div {
  margin: 3px 0;
}
.notification-menu ul li:hover {
  background: #fce02538;
}
.date-text {
  background: #0000004d;
  display: inline-block;
  padding: 3px 11px;
  font-size: 10px;
  border-radius: 11px;
  margin-bottom: 0 !important;
}
.description-text {
  font-size: 11px;
  font-weight: 300;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.security-notification-content {
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: #121518;
  border-radius: 5px;
  padding: 25px;
  margin-top: 20px;
}
.security-notification-content hr {
  opacity: 0.1;
}
.security-notification-content ul {
  padding-left: 20px;
}
.security-notification-content h6 {
  margin-bottom: 10px;
  color: #fce025;
}
.security-notification-content ul li {
  font-size: 14px;
  font-weight: 200;
  list-style: disc;
}
.keys-area {
  margin: 25px 0 0 20px;
}
.keys-area p {
  font-weight: 200;
  font-size: 14px;
}
.keys-area h5 {
  font-size: 15px;
  margin-bottom: 10px;
}
.width-25-wise.btn.btn-warning {
  width: 11%;
}
.wallet-info.main-bullet:after {
  display: none;
}
.form-control.is-invalid{
  border: 1px solid red !important;
}
.notification-menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.notification-top-left-box {
  display: flex;
  align-items: center;
}
.notification-menu-header .form-check {
  margin-bottom: 0;
  font-size: 13px;
  display: flex;
  align-items: center;
  font-weight: 300;
  padding-left: 15px;
}
.notification-menu-header .form-check input {
  margin: -1px 5px 0 0;
}
.security-detail-country {
  max-height: 100px;
  overflow: auto;
}
.security-detail-country p {
  padding: 3px 0;
}
.security-detail-table {
  max-height: 500px;
  overflow: auto;
}
td.security-deatil-wallet {
  width: 35%;
}
.secuity-filter-tp-right {
  display: flex;
  align-items: center;
}
.notification-menu img.notification-white-cross {
  width: 28px;
  min-width: 28px;
  margin-bottom: 6px;
}
.notification-menu button {
  color: #fce025;
  background: none;
  text-decoration: none;
  font-size: 11px;
  cursor: pointer;
  background: #090a0b;
  padding: 4px 12px;
  border-radius: 22px;
}
button.mark-as-resolved-btn {
  background: #fbdf25;
  color: #000;
  padding: 0px 7px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}

.priority-high {
  color: rgb(181, 81, 81);
  font-weight: 600;
}

.priority-medium {
  color: orange;
  font-weight: 600;
  font-size: 13px;
}

.priority-low {
  color: #02a302;
  font-weight: 600;
  font-size: 13px;
}
span.resolved-text {
  color: #fbdf25;
  font-weight: 400;
  font-size: 13px;
}
button.btn.btn-warning {
  background: #fce025;
  font-weight: 500;
  padding: 4px 13px;
  font-size: 15px;
  margin: 5px 0;
  border: none;
}
td.security-deatil-wallet i {
  color: #fff;
  margin-left: 7px;
  font-size: 10px;
  cursor: pointer;
}
.completed-transation.pending i {
  position: unset;
}
.security-notifications .UserListTable tbody tr td {
  padding: 12px 8px !important;
}
.mark-as-resolved-btn.status-mark-as-resolved-btn {
  background: transparent;
  color: #fbdf25;
  background: #fbdf2512;
}
.security-deatil-wallet-text {
  width: 62%;
  padding-right: 102px !important;
  white-space: unset !important;
}
.captcha-input {
  display: grid;
}
.captcha-input label {
  font-weight: 200;
  font-size: 14px;
  margin-right: 12px;
  color: #fff !important;
}
.captcha-input input {
  border: 1px solid #ffffff45 !important;
  border-radius: 5px;
  padding: 24px;
  color: #fff !important;
  background: transparent;
  font-weight: 400;
  opacity: 1;
  font-size: 14px;
  height: 52px;
  -webkit-text-fill-color: #989494 !important;
}
.home-slect .d-flex{
  justify-content: end ;
}
  .under-wallet .copied-text {
  left: 0 !important;
  position: unset !important;
  top: 0 !important;
  transform: unset !important;
}
.under-wallet {
  position: relative !important;
}
@media (max-width: 1700px) {
  .card-infor-box h3 {
    font-size: 14px;
  }

  .limit-order-section .css-b62m3t-container {
    width: 142px;
  }
}





@media (max-width: 1600px) {
  .system-log-area-date-picker input {
    width: 120px;
  }

  .toekn-main-paragraph {
    font-size: 11px;
    word-break: break-all;
  }

  .wallet-info svg {
    width: 9px;
  }

  .transactions-management-section .system-log-area-date-picker.d-flex input {
    font-size: 11px;
  }

  .transactions-management-section .react-select-main .css-13cymwt-control {
    font-size: 11px;
  }

  .transactions-management-section .css-b62m3t-container {
    width: 122px;
  }

  .transactions-management-section .position-relative.w-100.react-select-main.me-2 {
    width: fit-content !important;
  }

  .transactions-management-section.dashboard-main-area .form-control {
    padding: 10px 40px 10px 16px !important;
    font-size: 11px !important;
    width: 274px !important;
  }

  .limit-order-section .system-log-area-date-picker.d-flex input {
    font-size: 12px;
  }

  .limit-order-section .css-hlgwow {
    font-size: 12px;
  }

  .limit-order-section .css-b62m3t-container {
    width: 120px;
  }

  .card-infor-box h5 {
    font-size: 10px;
  }

  .card-infor-box {
    padding: 11px;
    align-items: center;
  }

  .card-infor-box h3 {
    font-size: 14px;
  }

  .css-ngwy7v-MuiResponsiveChart-container {
    height: 520px !important;
  }

  .system-log-area-date-picker.d-flex input {
    width: unset;
  }

  .scroll-data-div {
    height: 65vh;
  }

  .audit-request-box {
    height: 55vh;
  }

  .dashboard-main-area.min-height {
    padding: 52px 0;
  }

  /* .position-relative.width-inner-main {
    width: 322px;
  }
  .width-inner-main .all-dropdown-list .dropdown-toggle {
    width: 120px;
  } */
  .audit-buttons.inner-table button {
    width: 100%;
    margin-right: 0 !important;
    font-size: 11px !important;
  }

  .description-box {
    min-width: 158px;
  }

  .icons-actions .btn {
    width: 100%;
  }

  .detail-area-two .user-info p span {
    font-size: 11px;
  }

  .detail-area-two .user-info p {
    font-size: 12px;
  }

  .custom_height {
    min-height: 680px;
  }

  .mian_content.active .footer-content {
    margin-left: 245px;
  }

  .detail-area-two .user-info p {
    padding: 10px 0px 0 14px;
  }

  .dashboad-content {
    padding: 20px;
  }

  .mian_content.active .admin-header {
    margin-left: 230px;
  }

  .dashboard-left h3 {
    font-size: 22px;
  }

  .dashboard-right img {
    width: 40px;
    height: 40px;
  }

  td {
    font-size: 12px;
    white-space: nowrap;
  }

  th {
    font-size: 14px;
    white-space: nowrap;
  }

  .filter-data .css-b62m3t-container {
    width: 100px;
  }

  .dashboard-main-area .form-control {
    padding: 7px 40px 7px 16px;
  }

  .user-info p,
  .user-info p span {
    font-size: 14px;
  }

  .user-info p span i {
    font-size: 14px;
    margin-left: 5px;
  }

  .user-profile img {
    width: 75px;
    height: 75px;
  }

  .mian_content.active .content {
    padding-left: 235px;
  }

  .mian_content.active .barbtn {
    padding-left: 230px;
  }
}

@media (max-width: 1500px) {

  .dashboard-main-area.min-height.password-outer {
    padding: 34px 0;
  }

  .css-b62m3t-container {
    width: 162px;
  }
}

@media (max-width: 1400px) {
  .barbtn {
    left: 19%;
  }

  .fa-calendar-days {
    font-size: 11px;

  }

  .limit-order-section .main-search-area input.form-control.inner-input {
    min-width: 284px;
    font-size: 12px;
    padding: 10px;
  }

  .swap-reporting-section .select-days .form-select {
    margin-left: 0;
    ;
  }

  img.rounded-circle {
    min-width: 25px;
    min-height: 15px;
    width: 25px;
    height: 25px;
    margin-right: 3px;
  }

  .token-details span {
    line-height: 18px;
  }

  .UserListTable thead tr th {
    font-size: 11px;
  }

  .card-infor-box h5 span {
    white-space: nowrap;
  }

  .card-infor-box h5 {
    font-size: 10px;
    white-space: nowrap;
  }

  .dashboard-main-area {
    padding: 2px 2px 0 2px;
  }
}

@media (max-width: 1200px) {
  .mian_content.active .sidebar {
    left: -270px;
  }

  .mian_content .barbtn {
    padding-left: 240px;
  }

  .mian_content.active .barbtn {
    padding-left: 5px;
  }

  .mian_content.active .admin-header {
    margin-left: 0;
  }

  .mian_content.active .barbtn {
    padding-left: 5px;
  }

  .mian_content.active .content {
    padding-left: 0;
  }

  .mian_content.active .footer-content {
    margin-left: 0;
  }

  .mian_content .sidebar {
    left: 0;
  }
}